<template>
  <div>
    <div class="card">
      <div class="card-body">
        <Table :title="title" :columns="columns" :data="data" />
      </div>
    </div>
  </div>
</template>

<script>
import Table from "@/components/shared/usersTable";
import { mapActions } from "vuex";

const columns = [
  {
    title: "First Name",
    dataIndex: "first_name",
    className: "text-gray-6",
    scopedSlots: { 
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'firstName' 
    },
    onFilter: (value, record) =>
    {    
      if(record.first_name){
        return record.first_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      }
    },
  },
  {
    title: "Last Name",
    dataIndex: "last_name",
    className: "text-gray-6",
    scopedSlots: { 
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'lastName' 
    },
    onFilter: (value, record) =>
    {    
      if(record.last_name){
        return record.last_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      }
    },
  },
  {
    title: "Email",
    dataIndex: "email",
    className: "text-left text-gray-6",
    scopedSlots: { 
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'email' 
    },
    onFilter: (value, record) =>
    {    
      if(record.email){
        return record.email
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      }
    },
  },
  {
    title: "Phone",
    dataIndex: "phone",
    className: "text-left text-gray-6",
    scopedSlots: { 
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'phone' 
    },
    onFilter: (value, record) =>
    {    
      if(record.phone){
        return record.phone
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      }
    },
  },
  {
    title: "User Type",
    dataIndex: "user_type",
    className: "text-left text-gray-6 text-capitalize",
    scopedSlots: { customRender: "userType" },
    filters: [
      { text: 'Tourist', value: 'tourist' },
      { text: 'Tour Sight', value: 'tour_sight' },
      { text: 'Tour Operator', value: 'tour_operator' },
      { text: 'Hotel and Accomodations', value: 'hotel_and_accommodations' },
      { text: 'Food and Drinks', value: 'food_and_drinks' },
      { text: 'Events', value: 'events' },
      { text: 'Transport', value: 'transport' },
    ],
    onFilter: (value, record) => record.user_type.indexOf(value) === 0,
  },
];

export default {
  components: {
    Table,
  },
  data: function () {
    return {
      columns,
      data: [],
      title: "Pending Users",
    };
  },
  async mounted() {
    await this.getAllUsers();
  },
  methods: {
    ...mapActions("admin", {
      getUsers: "GET_PENDING_USERS",
    }),
    async getAllUsers() {
      this.data = await this.getUsers();
    },
    activate(record) {
      console.log("record", record);
    },
  },
};
</script>
